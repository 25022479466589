import { Link, navigate } from "gatsby";
import React, { Suspense, useContext } from "react";
import { CartContext } from "../../../context/shopContext";
import { useWindowDimension } from "../../utils/useWindowDimension";

const ShippedController = React.lazy(() => import("./ShippedController"));
const CartSubtotal = ({ recommendedProducts = [], onClose }) => {
  const { width, height } = useWindowDimension();
  const { estimatedCost, checkoutUrl, addToCart, cart } =
    useContext(CartContext);
  const isBrowser = typeof window !== "undefined";

  async function addProductToCart(product) {
    const productAddingToCart = {
      productId: product.admin_graphql_api_id,
      shopifyId: product.variants[0].admin_graphql_api_id,
      title:
        product.variants.length === 1
          ? product.title
          : product.variants[0].title,
      handle: product.handle,
      availableForSale: product.variants[0].inventory_quantity > 0,
      price: product.variants[0].price,
      image: product.image.src,
      quantity: 1,
    };
    addToCart(productAddingToCart, true);
  }

  return (
    <div className="h-40 sm:bg-transparent bottom-0 left-0 w-full py-2 sm:px-5 border-t border-solid border-t-[#E2E2E2]">
      <div className="w-full px-3 sm:px-0">
        {!!isBrowser && (
          <Suspense fallback={<div></div>}>
            {" "}
            <ShippedController />{" "}
          </Suspense>
        )}
        <div className="w-full flex items-start justify-between font-dinnext mt-2">
          <h5 className="capitalize text-sm leading-4 font-normal">
            subtotal
            <span className="block text-xs leading-[14px] text-black mt-2">
              *shipping and taxes will be calculated at checkout.
            </span>
          </h5>
          <h5 className="text-base leading-[18px] font-normal">
            $
            {estimatedCost?.subtotalAmount?.amount
              ? Number(estimatedCost?.subtotalAmount?.amount).toFixed(2)
              : Number(0).toFixed(2)}
          </h5>
        </div>
        <div className="w-full mt-5 text-center space-y-3">
          <button
            className="w-full md:w-[300px] bg-black h-[52px] text-white text-center font-medium"
            disabled={cart.length === 0}
            onClick={() =>
              navigate(
                `${checkoutUrl.replace(
                  "/cart/c",
                  "/checkouts/cn"
                )}&_gl=1*abcde5*`
              )
            }
          >
            Checkout
          </button>
        </div>
      </div>
      {width > 640 ? (
        height > 720 && (
          <div className="mt-4 hidden sm:block">
            <h5 className="text-center text-base sm:text-lg md:text-xl mb-2">
              Recommended Products
            </h5>
            <div className="max-w-md w-full mx-auto flex justify-between gap-x-2 mt-3">
              {recommendedProducts.map((product, index) => (
                <div key={index} className="relative w-full">
                  <button
                    className="absolute top-0 right-2 w-5 h-5 rounded-3xl bg-black text-white flex items-center justify-center z-10"
                    onClick={() => addProductToCart(product)}
                    aria-label="button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      className="w-4 h-4"
                    >
                      <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                  </button>
                  <Link
                    to={`/products/${product.handle}`}
                    key={index}
                    onClick={onClose}
                    className="relative w-[150px] h-[150px] rounded-full overflow-hidden space-y-4"
                  >
                    <img
                      src={product.image.src}
                      alt={product.title}
                      className="w-20 h-20 object-cover rounded-full overflow-hidden mx-auto"
                    />
                    <div className="space-y-2">
                      <h5 className="text-center leading-[18px]">
                        ${Number(product.variants[0].price).toFixed(2)}
                      </h5>
                      <h5 className="text-center leading-[18px]">
                        {product.title}
                      </h5>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        )
      ) : (
        <div className="mt-4 block sm:hidden">
          <h5 className="text-center text-base sm:text-lg md:text-xl mb-2">
            Recommended Products
          </h5>
          <div className="max-w-md w-full mx-auto flex justify-between gap-x-2 mt-3">
            {recommendedProducts.map((product, index) => (
              <div className="relative w-full">
                <button
                  className="absolute top-0 right-2 w-5 h-5 rounded-3xl bg-black text-white flex items-center justify-center z-10"
                  onClick={() => addProductToCart(product)}
                  aria-label="button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    className="w-4 h-4"
                  >
                    <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                  </svg>
                </button>
                <Link
                  to={`/products/${product.handle}`}
                  key={index}
                  onClick={onClose}
                  className="relative w-[150px] h-[150px] rounded-full overflow-hidden space-y-4"
                >
                  <img
                    src={product.image.src}
                    alt={product.title}
                    className="w-20 h-20 object-cover rounded-full overflow-hidden mx-auto"
                  />
                  <div className="space-y-2">
                    <h5 className="text-center leading-[18px]">
                      ${Number(product.variants[0].price).toFixed(2)}
                    </h5>
                    <h5 className="text-center leading-[18px]">
                      {product.title}
                    </h5>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CartSubtotal;
