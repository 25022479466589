import { graphql, useStaticQuery } from "gatsby";
import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  useRef,
} from "react";
import {
  createCustomer,
  createAccessToken,
  resetPassword,
  addCustomerAddress,
  getCustomerInfo,
  deleteCustomerAddress,
  updateCustomerAddress,
  defaultCustomerAddress,
  getOrderDetails,
} from "../lib/Account";
import {
  createCart,
  createCartWithCustomer,
  addItemToCart,
  updateCart,
  buyerIdentityUpdateInCart,
  getCart,
  removeItemFromCart,
} from "../lib/Cart";
import { useCookie } from "../components/utils/useCookie";

const CookieName = "good-light-profile";
const contextDefaultValues = {
  cart: [],
  setCart: () => {},
  myProfile: null,
  setMyProfile: () => {},
  addToCart: () => {},
  removeFromCart: () => {},
  updateQuantityCart: () => {},
  createAccount: () => {},
  loginAccount: () => {},
  logoutAccount: () => {},
  addAddress: () => {},
  getCustomer: () => {},
  deleteAddress: () => {},
  updateAddress: () => {},
  updateDefaultAddress: () => {},
  resetAccountPassword: () => {},
  cartOpen: false,
  setCartOpen: () => {},
  products: [],
  setProducts: () => {},
  searchResults: [],
  setSearchResults: () => {},
  cartId: "",
  setCartId: () => "",
  estimatedCost: null,
  setEstimatedCost: () => {},
  checkoutUrl: "",
  setCheckoutUrl: () => "",
  disabled: false,
  setDisabled: () => {},
  getOrders: () => {},
};

const CartContext = createContext(contextDefaultValues);

export function isObjectEmpty(obj) {
  return Object.keys(obj).length === 0;
}

const ShopProvider = ({ children }) => {
  let [cart, setCart] = useState([]);
  const [getCookie, removeCookie, updateCookie] = useCookie(CookieName);
  let [myProfile, setMyProfile] = useState(null);
  let [disabled, setDisabled] = useState(false);
  let [cartOpen, setCartOpen] = useState(false);
  let [products, setProducts] = useState([]);
  let [searchResults, setSearchResults] = useState([]);
  let [cartId, setCartId] = useState("");
  let [estimatedCost, setEstimatedCost] = useState(null);
  let [checkoutUrl, setCheckoutUrl] = useState("");

  const data = useStaticQuery(graphql`
    {
      shopifyCollection(handle: { eq: "good-light-products" }) {
        products {
          id
          title
          handle
          featuredImage {
            src
          }
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    const getProfile = getCookie(CookieName);
    if (getProfile) {
      setMyProfile(getProfile);
    }
    setProducts(data?.shopifyCollection?.products);
    if (localStorage.getItem("good-light-world-cart")) {
      const goodLightCart = JSON.parse(
        localStorage.getItem("good-light-world-cart")
      );
      // if (goodLightCart.products) {
      //   setCart(goodLightCart.products);
      // }
      // if (goodLightCart.checkout) {
      //   setCheckoutUrl(goodLightCart.checkout);
      // }
      // if (goodLightCart.estimatedCost) {
      //   setEstimatedCost(goodLightCart.estimatedCost);
      // }
      if (goodLightCart.cardId) {
        setCartId(goodLightCart.cardId);
        getCartData(goodLightCart.cardId, goodLightCart.products);
      }
    }
  }, [data?.shopifyCollection?.products, cartId]);

  useEffect(() => {
    if (cart.length > 0 && estimatedCost?.subtotalAmount?.amount) {
      const variantId = cart.find(
        (item) =>
          item.shopifyId === "gid://shopify/ProductVariant/41839266660539"
      );

      const giftProduct = cart.find(
        (item) => item.productId === "gid://shopify/Product/8708477878488"
      );

      if (variantId && !giftProduct) {
        const requestParameters = new URLSearchParams({
          format: "pretty",
          key: "f685177dac4b4b9eda68eecd33aaf79ec20edbcc",
          limit: 3,
          "cart[items][0][product_id]": "7163120779451",
          "cart[items][0][variant_id]": "41839266660539",
          // shopify_variant_ids: ids.join(","),
          // cart_subtotal: Number(estimatedCost.subtotalAmount.amount) * 100,
        });

        fetch(
          `https://rebuyengine.com/api/v1/custom/id/183162?${requestParameters}`
        )
          .then((response) => response.json())
          .then((data) => {
            const cartProducts = new Set(cart.map((item) => item.productId));
            // Iterate through array1 and check if id exists in array2
            if (data.data.length > 0) {
              data.data.some((item) => {
                if (
                  cart.length > 0
                  //  &&
                  // Number(estimatedCost?.subtotalAmount?.amount) > 50
                ) {
                  if (!cartProducts.has(item.admin_graphql_api_id)) {
                    addProductToCart(item);

                    return true;
                  }
                }

                return false;
              });
            } else {
              if (
                cart.length > 0 &&
                Number(estimatedCost?.subtotalAmount?.amount) <= 50
              ) {
                const removeGiftItem = cart.find(
                  (item) => Number(item.price) === 0
                );
                if (removeGiftItem) {
                  removeFromCart(removeGiftItem);
                }
              }
            }
          });
      } else if (!variantId && giftProduct) {
        removeGiftFromCart(giftProduct);
      }
    }
  }, [cart]);

  async function addProductToCart(product) {
    const productAddingToCart = {
      productId: product.admin_graphql_api_id,
      shopifyId: product.variants[0].admin_graphql_api_id,
      title:
        product.variants.length === 1
          ? product.title
          : product.variants[0].title,
      handle: product.handle,
      availableForSale: product.variants[0].inventory_quantity > 0,
      price: product.variants[0].price,
      image: product.image.src,
      quantity: 1,
    };
    addToCart(productAddingToCart, true);
  }
  async function removeGiftFromCart(product) {
    const productAddingToCart = {
      productId: product.productId,
      shopifyId: product.shopifyId,
      title: product.title,
      quantity: product.quantity,
      lineId: product.lineId,
    };
    removeFromCart(productAddingToCart);
  }

  // ========================================================================================================================== //
  // ========================================================== CART ========================================================== //
  // ========================================================================================================================== //
  const goodLightPoster = {
    title: "good light poster",
    merchandiseId: "gid://shopify/ProductVariant/40467789807803",
    quantity: 1,
  };
  const greetingCard = {
    title: "greeting card",
    merchandiseId: "gid://shopify/ProductVariant/42468568039640",
    quantity: 1,
  };

  const productToAddGift = {
    id: "gid://shopify/Product/6577362895035",
    variantId: "gid://shopify/ProductVariant/39441941889211",
  };

  async function getCartData(id, products) {
    let newCart = [];
    const response = await getCart(id);

    if (response?.id) {
      setCheckoutUrl(response.checkoutUrl);
      setEstimatedCost(response.estimatedCost);
      response.lines?.nodes.map((item) => {
        let added = false;
        products.map((cartItem) => {
          if (
            cartItem.shopifyId === item.merchandise.id &&
            cartItem.sellingPlanId ===
              item.sellingPlanAllocation?.sellingPlan.id
          ) {
            added = true;
            return newCart.push({
              ...cartItem,
              shopifyId: item.merchandise.id,
              quantity: item.quantity,
              lineId: item.id,
              productId: item.merchandise.product.id,
              sellingPlanId: item.sellingPlanAllocation?.sellingPlan.id,
              sellingPlanName: item.sellingPlanAllocation?.sellingPlan.name,
              price: cartItem.sellingPlanId
                ? item.sellingPlanAllocation?.priceAdjustments[0].price.amount
                : cartItem.price,
            });
          }
        });

        if (!added) {
          added = true;
          return newCart.push({
            handle: item.merchandise.product.handle,
            productId: item.merchandise.product.id,
            image: item.merchandise.image.url,
            sellingPlanId: item.sellingPlanAllocation?.sellingPlan.id,
            sellingPlanName: item.sellingPlanAllocation?.sellingPlan.name,
            price: item.sellingPlanAllocation
              ? item.sellingPlanAllocation?.priceAdjustments[0].price.amount
              : item.merchandise.priceV2.amount,
            quantity: item.quantity,
            title:
              item.merchandise.title !== "Default Title"
                ? item.merchandise.title === "billboard" &&
                  item.merchandise.product.title
                : item.merchandise.product.title,
            shopifyId: item.merchandise.id,
            lineId: item.id,
          });
        }
      });

      setCart(newCart);
      setCartId(response.id);

      localStorage.setItem(
        "good-light-world-cart",
        JSON.stringify({
          products: newCart,
          checkout: response.checkoutUrl,
          estimatedCost: response.estimatedCost,
          cardId: response.id,
        })
      );
    } else {
      localStorage.removeItem("good-light-world-cart");
    }
  }
  async function addToCart(product, rebuy) {
    setCartOpen(true);
    setDisabled(true);
    try {
      if (cart.length === 0) {
        let cartResponse;
        if (myProfile) {
          if (product.productId === productToAddGift.id) {
            cartResponse = await createCartWithCustomer(
              {
                quantity: product.quantity,
                merchandiseId: `${product.shopifyId}`,
                sellingPlanId: product.sellingPlanId,
              },
              {
                quantity: 1,
                merchandiseId: goodLightPoster.merchandiseId,
                sellingPlanId: "",
              },
              {
                quantity: 1,
                merchandiseId: greetingCard.merchandiseId,
                sellingPlanId: "",
              },
              myProfile,
              rebuy
            );
          } else {
            cartResponse = await createCartWithCustomer(
              {
                quantity: product.quantity,
                merchandiseId: `${product.shopifyId}`,
                sellingPlanId: product.sellingPlanId,
              },
              {
                quantity: 1,
                merchandiseId: goodLightPoster.merchandiseId,
                sellingPlanId: "",
              },
              undefined,
              myProfile,
              rebuy
            );
          }
          setCheckoutUrl(cartResponse.checkoutUrl);
          setEstimatedCost(cartResponse.estimatedCost);
        } else {
          if (product.productId === productToAddGift.id) {
            cartResponse = await createCart(
              {
                quantity: product.quantity,
                merchandiseId: `${product.shopifyId}`,
                sellingPlanId: product.sellingPlanId,
              },
              {
                quantity: 1,
                merchandiseId: goodLightPoster.merchandiseId,
                sellingPlanId: "",
              },
              {
                quantity: 1,
                merchandiseId: greetingCard.merchandiseId,
                sellingPlanId: "",
              },
              rebuy
            );
          } else {
            cartResponse = await createCart(
              {
                quantity: product.quantity,
                merchandiseId: `${product.shopifyId}`,
                sellingPlanId: product.sellingPlanId,
              },
              {
                quantity: 1,
                merchandiseId: goodLightPoster.merchandiseId,
                sellingPlanId: "",
              },
              null,
              rebuy
            );
          }
          setCheckoutUrl(cartResponse.checkoutUrl);
          setEstimatedCost(cartResponse.estimatedCost);
          setCartId(cartResponse.id);
        }

        if (product.productId === productToAddGift.id) {
          setCart([
            { ...product, lineId: cartResponse.lines?.nodes[0].id },
            // { ...goodLightPoster, lineId: cartResponse.lines?.nodes[1].id },
            // { ...greetingCard, lineId: cartResponse.lines?.nodes[2].id },
          ]);
          localStorage.setItem(
            "good-light-world-cart",
            JSON.stringify({
              products: [
                {
                  ...product,
                  productId: product.productId,
                  lineId: cartResponse.lines?.nodes[0].id,
                  sellingPlanId: product.sellingPlanId,
                  sellingPlanName: product.sellingPlanId
                    ? cartResponse.lines?.nodes[0].sellingPlanAllocation
                        .sellingPlan.name
                    : "",
                  price: product.sellingPlanId
                    ? cartResponse.lines?.nodes[0].sellingPlanAllocation
                        ?.priceAdjustments[0].price.amount
                    : product.price,
                },
                // {
                //   ...goodLightPoster,
                //   lineId: cartResponse.lines?.nodes[1].id,
                //   sellingPlanId: goodLightPoster.sellingPlanId,
                //   sellingPlanName: goodLightPoster.sellingPlanId
                //     ? cartResponse.lines?.nodes[1].sellingPlanAllocation
                //         .sellingPlan.name
                //     : "",
                //   price: goodLightPoster.sellingPlanId
                //     ? cartResponse.lines?.nodes[1].sellingPlanAllocation
                //         ?.priceAdjustments[1].price.amount
                //     : cartResponse.lines?.nodes[1].merchandise.priceV2.amount,
                // },
                // {
                //   ...greetingCard,
                //   lineId: cartResponse.lines?.nodes[2].id,
                //   sellingPlanId: greetingCard.sellingPlanId,
                //   sellingPlanName: greetingCard.sellingPlanId
                //     ? cartResponse.lines?.nodes[2].sellingPlanAllocation
                //         .sellingPlan.name
                //     : "",
                //   price: greetingCard.sellingPlanId
                //     ? cartResponse.lines?.nodes[2].sellingPlanAllocation
                //         ?.priceAdjustments[2].price.amount
                //     : cartResponse.lines?.nodes[2].merchandise.priceV2.amount,
                // },
              ],
              checkout: cartResponse.checkoutUrl,
              estimatedCost: cartResponse.estimatedCost,
              cardId: cartResponse.id,
            })
          );
        } else {
          setCart([
            {
              ...product,
              lineId: cartResponse.lines?.nodes[0].id,
              productId: product.productId,
            },
            // { ...goodLightPoster, lineId: cartResponse.lines?.nodes[1].id },
          ]);
          localStorage.setItem(
            "good-light-world-cart",
            JSON.stringify({
              products: [
                {
                  ...product,
                  productId: product.productId,
                  lineId: cartResponse.lines?.nodes[0].id,
                  sellingPlanId: product.sellingPlanId,
                  sellingPlanName: product.sellingPlanId
                    ? cartResponse.lines?.nodes[0].sellingPlanAllocation
                        .sellingPlan.name
                    : "",
                  price: product.sellingPlanId
                    ? cartResponse.lines?.nodes[0].sellingPlanAllocation
                        ?.priceAdjustments[0].price.amount
                    : product.price,
                },
                // {
                //   ...goodLightPoster,
                //   lineId: cartResponse.lines?.nodes[1].id,
                //   sellingPlanId: goodLightPoster.sellingPlanId,
                //   sellingPlanName: goodLightPoster.sellingPlanId
                //     ? cartResponse.lines?.nodes[1].sellingPlanAllocation
                //         .sellingPlan.name
                //     : "",
                //   price: goodLightPoster.sellingPlanId
                //     ? cartResponse.lines?.nodes[1].sellingPlanAllocation
                //         ?.priceAdjustments[1].price.amount
                //     : cartResponse.lines?.nodes[1].merchandise.priceV2.amount,
                // },
              ],
              checkout: cartResponse.checkoutUrl,
              estimatedCost: cartResponse.estimatedCost,
              cardId: cartResponse.id,
            })
          );
        }
      } else {
        const goodLightCart = JSON.parse(
          localStorage.getItem("good-light-world-cart")
        );
        let newCart = [];
        let cartResponse;

        cartResponse = await addItemToCart(
          goodLightCart.cardId,
          {
            quantity: product.quantity,
            merchandiseId: product.shopifyId,
            sellingPlanId: product.sellingPlanId,
          },
          undefined,
          rebuy
        );

        console.log(product, cartResponse);

        setCheckoutUrl(cartResponse.checkoutUrl);
        setEstimatedCost(cartResponse.estimatedCost);
        getCartData(goodLightCart.cardId, goodLightCart.products);
      }
    } catch (error) {
      console.error(error);
    }
    fbCartEvent(product);
    attentiveCartEvent(product);
    setDisabled(false);
  }

  function userExists(id, arr) {
    return arr?.some(function (el) {
      return el.merchandise.id === id;
    });
  }
  async function updateQuantityCart(product) {
    const goodLightCart = JSON.parse(
      localStorage.getItem("good-light-world-cart")
    );

    setDisabled(true);
    let newCart = [];
    try {
      const cartResponse = await updateCart(goodLightCart.cardId, product);
      setCheckoutUrl(cartResponse.checkoutUrl);
      setEstimatedCost(cartResponse.estimatedCost);
      let giftInAPI = userExists(
        productToAddGift.variantId,
        cartResponse.lines?.nodes
      );

      if (giftInAPI) {
        cartResponse.lines?.nodes.map((item) => {
          let added = false;
          cart.map((cartItem) => {
            if (
              cartItem.shopifyId === item.merchandise.id &&
              cartItem.sellingPlanId ===
                item.sellingPlanAllocation?.sellingPlan.id
            ) {
              added = true;
              return newCart.push({
                ...cartItem,
                quantity: item.quantity,
                lineId: item.id,
                price: cartItem.sellingPlanId
                  ? item.sellingPlanAllocation?.priceAdjustments[0].price.amount
                  : cartItem.price,
              });
            }
          });
        });
      } else {
        cartResponse.lines?.nodes.map((item) => {
          let added = false;
          cart.map((cartItem) => {
            if (
              cartItem.shopifyId === item.merchandise.id &&
              cartItem.sellingPlanId ===
                item.sellingPlanAllocation?.sellingPlan.id
            ) {
              added = true;

              // if (cartItem.shopifyId !== greetingCard.merchandiseId) {
              // if (cartResponse.lines?.nodes.length !== 1) {
              return newCart.push({
                ...cartItem,
                quantity: item.quantity,
                lineId: item.id,
                price: cartItem.sellingPlanId
                  ? item.sellingPlanAllocation?.priceAdjustments[0].price.amount
                  : cartItem.price,
              });
              // } else if (
              //   cartResponse.lines?.nodes.length === 1
              //   //  && cartItem.shopifyId === goodLightPoster.merchandiseId
              // ) {
              //   updateQuantityCart({
              //     ...cartItem,
              //     quantity: item.quantity - 1,
              //     lineId: item.id,
              //   });
              // }
              // }
              // else {
              //   if (cartItem.shopifyId === greetingCard.merchandiseId) {
              //     updateQuantityCart({
              //       ...cartItem,
              //       quantity: item.quantity - 1,
              //       lineId: item.id,
              //     });
              //   }
              // }
            }
          });
        });
      }

      setCart(newCart);

      localStorage.setItem(
        "good-light-world-cart",
        JSON.stringify({
          products: newCart,
          checkout: cartResponse.checkoutUrl,
          estimatedCost: cartResponse.estimatedCost,
          cardId: cartResponse.id,
        })
      );
    } catch (error) {
      console.error(error);
    }
    setDisabled(false);
  }

  async function removeFromCart(product) {
    const goodLightCart = JSON.parse(
      localStorage.getItem("good-light-world-cart")
    );

    await removeItemFromCart(goodLightCart.cardId, product);
    if (goodLightCart.cardId) {
      setCartId(goodLightCart.cardId);
      getCartData(goodLightCart.cardId, goodLightCart.products);
    }
  }

  // ========================================================================================================================== //
  // ========================================================= ACCOUNT ======================================================== //
  // ========================================================================================================================== //

  async function createAccount(profile) {
    setDisabled(true);
    try {
      const createCustomerResponse = await createCustomer(profile);
      if (createCustomerResponse.customer) {
        const createAccessTokenResponse = await createAccessToken(profile);
        if (cart.length > 0) {
          await buyerIdentityUpdateInCart(cartId, {
            ...createCustomerResponse.customer,
            ...createAccessTokenResponse,
          });
        }

        setMyProfile(createCustomerResponse.customer);
        updateCookie(
          CookieName,
          JSON.stringify({
            ...createCustomerResponse.customer,
            token: createAccessTokenResponse.accessToken,
          })
        );
        localStorage.setItem(
          "good-light-world-cart",
          JSON.stringify({
            products: cart,
            checkout: checkoutUrl,
            estimatedCost: estimatedCost,
            cardId: cartId,
          })
        );
      } else {
        return createCustomerResponse.customerUserErrors[0];
      }
    } catch (error) {
      console.error(error);
    }
    setDisabled(false);
  }

  async function loginAccount(customer) {
    setDisabled(true);
    try {
      const createAccessTokenResponse = await createAccessToken(customer);

      if (createAccessTokenResponse.customerAccessToken) {
        updateCookie(
          CookieName,
          JSON.stringify({
            ...customer,
            token: createAccessTokenResponse.customerAccessToken.accessToken,
            expiresAt: createAccessTokenResponse.customerAccessToken.expiresAt,
          })
        );
        setMyProfile({
          email: customer.email,
          token: createAccessTokenResponse.customerAccessToken.accessToken,
        });
        setDisabled(false);
        return createAccessTokenResponse.customerAccessToken;
      } else {
        setDisabled(false);
        return createAccessTokenResponse.customerUserErrors[0].code;
      }
    } catch (error) {
      throw new Error(error);
    }
  }

  async function resetAccountPassword(email) {
    setDisabled(true);
    try {
      const resetPasswordResponse = await resetPassword(email);
      setDisabled(false);
      return resetPasswordResponse;
    } catch (error) {
      throw new Error(error);
    }
  }

  async function getCustomer() {
    setDisabled(true);
    try {
      const getCustomerResponse = await getCustomerInfo(myProfile.token);
      setDisabled(false);
      return getCustomerResponse;
    } catch (error) {
      setDisabled(false);
      throw new Error(error);
    }
  }

  async function addAddress(customerDetails) {
    setDisabled(true);
    try {
      const addAddressResponse = await addCustomerAddress(
        myProfile.token,
        customerDetails
      );
      const response = await getCustomerInfo(myProfile.token);
      setDisabled(false);
      return response;
    } catch (error) {
      setDisabled(false);
      throw new Error(error);
    }
  }

  async function logoutAccount() {
    setDisabled(true);
    removeCookie(CookieName);
    setMyProfile(null);
    setDisabled(false);
  }

  async function deleteAddress(id) {
    setDisabled(true);
    try {
      const deleteAddressResponse = await deleteCustomerAddress(
        myProfile.token,
        id
      );
      const response = await getCustomerInfo(myProfile.token);
      setDisabled(false);
      return response;
    } catch (error) {
      setDisabled(false);
      throw new Error(error);
    }
  }

  async function updateAddress(id, customer) {
    setDisabled(true);
    try {
      const updateAddressResponse = await updateCustomerAddress(
        myProfile.token,
        id,
        customer
      );
      const response = await getCustomerInfo(myProfile.token);
      setDisabled(false);
      return response;
    } catch (error) {
      setDisabled(false);
      throw new Error(error);
    }
  }

  async function updateDefaultAddress(id) {
    setDisabled(true);
    try {
      const defaultAddressResponse = await defaultCustomerAddress(
        myProfile.token,
        id
      );
      const response = await getCustomerInfo(myProfile.token);
      setDisabled(false);
      return response;
    } catch (error) {
      setDisabled(false);
      throw new Error(error);
    }
  }

  async function getOrders() {
    setDisabled(true);
    try {
      const getOrders = await getOrderDetails(myProfile.token);
      setDisabled(false);
      return getOrders;
    } catch (error) {
      setDisabled(false);
      throw new Error(error);
    }
  }

  function fbCartEvent(product) {
    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "AddToCart", {
          content_ids: [product.shopifyId],
          content_name: product.title,
          content_type: "product",
          currency: "USD",
          value: product.price,
        });
      }
    }
  }

  function attentiveCartEvent(product) {
    if (typeof window !== "undefined") {
      if (window.attentive != null) {
        window.attentive.analytics.addToCart({
          items: [
            {
              productId: product.productId,
              productVariantId: product?.shopifyId,
              name: product?.title,
              productImage: product?.image,
              price: {
                value: product?.price,
                currency: "USD",
              },
            },
          ],
          cart: {
            cartId: JSON.parse(localStorage.getItem("good-light-world-cart"))
              ?.cardId,
          },
        });
      }
    }
  }

  return (
    <CartContext.Provider
      value={{
        cart,
        cartOpen,
        setCartOpen,
        addToCart,
        checkoutUrl,
        removeFromCart,
        createAccount,
        loginAccount,
        logoutAccount,
        addAddress,
        getCustomer,
        deleteAddress,
        updateAddress,
        updateDefaultAddress,
        resetAccountPassword,
        updateQuantityCart,
        products,
        searchResults,
        setSearchResults,
        estimatedCost,
        cartId,
        myProfile,
        disabled,
        getOrders,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

const ShopConsumer = CartContext.Consumer;
export default ShopProvider;
export { ShopConsumer, CartContext };
